import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
// import speakerIcon from "../assets/images/speaker.svg";
// import speakerMutedIcon from "../assets/images/speaker-muted.svg";
// import instagramIcon from "../assets/images/instagram.svg";
// import telegramIcon from "../assets/images/telegram.svg";
// import music from "../assets/sounds/music.mp3";

const speakerIcon = (
  <svg
    width={24}
    height={24}
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <path
      fill="#FFFFFF"
      d="M15 23l-9.309-6h-5.691v-10h5.691l9.309-6v22zm-9-15.009v8.018l8 5.157v-18.332l-8 5.157zm14.228-4.219c2.327 1.989 3.772 4.942 3.772 8.229 0 3.288-1.445 6.241-3.77 8.229l-.708-.708c2.136-1.791 3.478-4.501 3.478-7.522s-1.342-5.731-3.478-7.522l.706-.706zm-2.929 2.929c1.521 1.257 2.476 3.167 2.476 5.299 0 2.132-.955 4.042-2.476 5.299l-.706-.706c1.331-1.063 2.182-2.729 2.182-4.591 0-1.863-.851-3.529-2.184-4.593l.708-.708zm-12.299 1.299h-4v8h4v-8z"
    />
  </svg>
);

const speakerMutedIcon = (
  <svg
    width={24}
    height={24}
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <path
      fill="#FFFFFF"
      d="M18 23l-9.305-5.998.835-.651 7.47 4.815v-10.65l1-.781v13.265zm0-15.794l5.384-4.206.616.788-23.384 18.264-.616-.788 5.46-4.264h-2.46v-10h5.691l9.309-6v6.206zm-11.26 8.794l1.26-.984v-7.016h-4v8h2.74zm10.26-8.013v-5.153l-8 5.157v6.244l8-6.248z"
    />
  </svg>
);

const instagramIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="#FA453C"
    viewBox="0 0 24 24"
    className="glow"
  >
    <path d="M14.667 12c0 1.473-1.194 2.667-2.667 2.667-1.473 0-2.667-1.193-2.667-2.667 0-1.473 1.194-2.667 2.667-2.667 1.473 0 2.667 1.194 2.667 2.667zm3.846-3.232c.038.843.046 1.096.046 3.232s-.008 2.389-.046 3.233c-.1 2.15-1.109 3.181-3.279 3.279-.844.038-1.097.047-3.234.047-2.136 0-2.39-.008-3.232-.046-2.174-.099-3.181-1.132-3.279-3.279-.039-.845-.048-1.098-.048-3.234s.009-2.389.047-3.232c.099-2.152 1.109-3.181 3.279-3.279.844-.039 1.097-.047 3.233-.047s2.39.008 3.233.046c2.168.099 3.18 1.128 3.28 3.28zm-2.405 3.232c0-2.269-1.84-4.108-4.108-4.108-2.269 0-4.108 1.839-4.108 4.108 0 2.269 1.84 4.108 4.108 4.108 2.269 0 4.108-1.839 4.108-4.108zm1.122-4.27c0-.53-.43-.96-.96-.96s-.96.43-.96.96.43.96.96.96c.531 0 .96-.43.96-.96zm6.77-7.73v24h-24v-24h24zm-4 12c0-2.172-.009-2.445-.048-3.298-.131-2.902-1.745-4.52-4.653-4.653-.854-.04-1.126-.049-3.299-.049s-2.444.009-3.298.048c-2.906.133-4.52 1.745-4.654 4.653-.039.854-.048 1.127-.048 3.299 0 2.173.009 2.445.048 3.298.134 2.906 1.746 4.521 4.654 4.654.854.039 1.125.048 3.298.048s2.445-.009 3.299-.048c2.902-.133 4.522-1.745 4.653-4.654.039-.853.048-1.125.048-3.298z" />
  </svg>
);

const telegramIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#FA453C"
    width={36}
    height={36}
    viewBox="0 0 24 24"
    className="glow"
  >
    <path d="m12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12c0-6.627-5.373-12-12-12zm5.894 8.221-1.97 9.28c-.145.658-.537.818-1.084.508l-3-2.21-1.446 1.394c-.14.18-.357.295-.6.295-.002 0-.003 0-.005 0l.213-3.054 5.56-5.022c.24-.213-.054-.334-.373-.121l-6.869 4.326-2.96-.924c-.64-.203-.658-.64.135-.954l11.566-4.458c.538-.196 1.006.128.832.941z" />
  </svg>
);

const Footer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const audioRef = useRef(null);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.currentTime = 25;
      audioRef.current.volume = 0.25;
      audioRef.current.play();
    }
  }, []);

  const togglePlay = () => {
    console.log("togglePlay");
    console.log(isPlaying);
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTutorialClick = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <div
      style={{ backgroundColor: "transparent" }}
      expand="lg"
      className="footer-fixed flex flex-col"
    >
      <div className="flex justify-content-between mx-10">
        <button
          onClick={togglePlay}
          style={{ border: "none" }}
          className="hover:bg-transparent"
        >
          {isPlaying ? speakerIcon : speakerMutedIcon}
        </button>
        <audio id="music" ref={audioRef} autoPlay loop muted={!isPlaying}>
          <source src="/assets/music.mp3" type="audio/mp3" />
        </audio>

        <div className="flex space-x-2 gap-4 ml-8">
          <a href="https://t.me/sknft" target="_blank" rel="noreferrer">
            {telegramIcon}
          </a>
          <a
            href="https://www.instagram.com/enriquemrin"
            target="_blank"
            rel="noreferrer"
          >
            {instagramIcon}
          </a>
        </div>

        <button onClick={handleTutorialClick}>Anleitung</button>

        <Modal
          show={showModal}
          onHide={handleCloseModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body 
            style={{ 
              // padding: "0px", 
              backgroundColor: "#273138",
            }}
          >
            <video width="100%" controls>
              {/* <source src="video.mp4" type="video/mp4" /> */}
              <source src="/assets/tutorial.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Modal.Body>
        </Modal>
      </div>
      <div className="nav_cover flex justify-content-center py-4">
        {"© 2023 Enrique Marin. Alle Rechte vorbehalten."}
      </div>
    </div>
  );
};

export default Footer;
