import React, { useState, useEffect } from "react";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import { BoredMint } from "./BoredMint";
import "../index.css";
import { useModal } from "../context/ModalContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NFTContract as readOnlyNFT } from "../config";

function Mint() {
  const [maxNfts, setMaxNfts] = useState(1);
  const [totalSupply, setTotalSupply] = useState(100);
  const [mintedNfts, setMintedNfts] = useState(0);
  const [userMinted, setUserMinted] = useState(0);
  const [count, setCount] = useState(51);
  const [isHovered, setIsHovered] = useState(false);
  const [buttonText, setButtonText] = useState("Wallet verbinden");

  const {
    account,
    NFTContract,
    web3api,
    getWalletAddress,
    connectWalletModalHandle,
    isWalletAlreadyConnected,
    disconnectWalletFromApp,
    newConnectWallet,
  } = useModal();

  useEffect(() => {
    readOnlyNFT.methods
      .totalSupply()
      .call()
      .then((supply) => {
        setMintedNfts(supply);
      });
  }, []);

  useEffect(() => {
    if (account) {
      readOnlyNFT.methods
        .balanceOf(account[0])
        .call()
        .then((balance) => {
          setUserMinted(balance);
        });
      setButtonText("REDPILL");
    } else {
      setButtonText("Wallet verbinden");
    }
  }, [account]);

  const increment = () => {
    if (count < 10) {
      setCount(count + 1);
      // var cost = mintCost;
      // setAmount(totalCost + oneMintCost);
    }
  };

  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  // Modify your handleMint function to use the callback
  const handleMint = async () => {
    if (userMinted >= maxNfts) {
      toast.error("You can only mint one NFT per wallet!");
      return;
    }

    try {
      if (!account) {
        newConnectWallet();
        return;
      } else {
        const gasAmount = await NFTContract.methods
          .publicMint()
          .estimateGas({
            from: account[0],
          })
          .catch((error) => {
            error.message =
              "There was an error minting your NFT. Please try again.";
            toast.error(error.message);
          });
        const gasPrice = await web3api.eth.getGasPrice();
        const gasCost = gasAmount * gasPrice;
        await NFTContract.methods
          .publicMint()
          .send({
            from: account[0],
            gas: gasAmount*2,
            gasPrice: gasPrice,
          })
          .on("receipt", (receipt) => {
            toast.success("NFT minted successfully");
            setMintedNfts((mintedNfts) => parseInt(mintedNfts) + 1);
            setUserMinted((userMinted) => parseInt(userMinted) + 1);
          })
          .on("error", (error) => {
            error.message =
              "There was an error minting your NFT. Please try again.";
            toast.error(error.message);
          });
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const content = (
    <>
      <Container fluid>
        <Container className="center__mint__area">
          <Row className="mint-area">
            <Col xs={12} md={6}>
              <img
                src="/assets/pill-gif.gif"
                style={{
                  width: "60%",
                }}
                className="landing__page__gif mx-auto d-block -mt-20"
                alt=" "
              />{" "}
              <br></br>
              <p className="description">
                Tauche ein in die REDPILL-Welt und erlebe das einzigartige
                Gefühl, Teil des allerersten "Escape The Matrix" Events in
                Deutschland gewesen zu sein! Sichere dir jetzt deine REDPILL und
                sei gespannt auf mögliche exklusive Vorteile, die nur den
                REDPILL-Inhabern vorbehalten sind. Wer weiß, welche aufregenden
                Überraschungen dich erwarten... Sei ein Pionier und sei dabei!
              </p>
            </Col>
            <Col xs={18} md={6}>
              <Card className="text-center mint-card">
                <Card.Body className="text-center">
                  <Row>
                    <Col xs={12} md={12} style={{ padding: "20px" }}>
                      <div className="mint-action-box flex flex-col">
                        {/* <BoredMint
                          minted={mintedNfts}
                          totalSupply={totalSupply}
                        /> */}
                        <div className="mint-box-container">
                          <div className="mint-box-header">
                            {/* <h1 className="sale-head text-justify"> */}
                            <div
                              className="glitch"
                              // style={{fontFamily: 'Glitch'}}
                            >
                              <h1 className="sale-head">
                                MINTE DEIN
                                <span className="text-gradient">
                                  {" "}
                                  REDPILL NFT
                                </span>
                              </h1>
                            </div>
                            {/* <br /> */}
                            <p className="text-center text-lg">
                              Eine exklusive NFT Kollektion von Enrique Marin
                            </p>
                            <div className="mt-20">
                              <div className="">
                                <span className="border-anim">
                                {/* <span className=""> */}
                                  {mintedNfts}/{totalSupply}
                                </span>
                              </div>
                              {/* {mintedNfts === totalSupply ? (
                                <>
                                  <h4>The sale has ended.</h4>
                                  <Button
                                    className="btn-connect"
                                    variant="primary"
                                  >
                                    <a
                                      target={"_blank"}
                                      href={"https://opensea.io"}
                                      rel="noreferrer"
                                    >
                                      Opensea.io
                                    </a>
                                  </Button>
                                </>
                              ) : 
                              // <>
                              //   <h4>Holen Sie sich jetzt Ihre REDPILL!</h4>
                              // </>
                              null} */}
                            </div>
                          </div>
                          <br></br>
                        </div>
                        <div className="flex flex-col md:flex-row items-center justify-center space-y-10 md:space-y-0 md:space-x-6">
                          <a
                            href="https://www.netflix.com/de"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Button
                              className="button-new display netflix white-glow"
                              variant="outline-secondary"
                            >
                              NETFLIX
                            </Button>
                          </a>
                          <div className={`${account ? "animate" : ""}`}>
                            <Button
                              className={`button-new display pill ${
                                isHovered ? "hovered" : ""
                              } ${account ? "pill-button" : "red-glow"}`}
                              variant="outline-secondary"
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                              onClick={handleMint}
                            >
                              {buttonText}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );

  return (
    <>
      <div className="section main_slider mt-0" id="home">
        {/* <Container fluid>
          <div className="videoWrapper"></div>
          <div
            id="videoWrapper1"
            // style={{ minHeight: 600 }}
            className="videoWrapper1"
          ></div>
        </Container> */}
        <div>{content}</div>
      </div>
    </>
  );
}

export default Mint;
