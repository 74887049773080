import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Web3 from "web3";

// disconnect metamask wallet
export const disconnectWallet = () => {
  localStorage.removeItem("isWalletConnected");
  localStorage.removeItem("address");
  toast.info("Wallet disconnected");
};

const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const alchemyweb3 = createAlchemyWeb3(
  "wss://eth-mainnet.g.alchemy.com/v2/zYi9iUfRHxDz4Q3AoIqULNHmSntxHG3s"
  // "wss://eth-goerli.g.alchemy.com/v2/7EfQXZjgQZEY0MK_CCRLlG1S_TvOfzsr"
);
export const web3 = alchemyweb3;

const NFTAddress = "0x94325C035E03D032f7d44825Bfc5897c062B6214";
const NFTABI = [
  {
    inputs: [],
    name: "publicMint",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "balanceOf",
    type: "function",
    inputs: [
      {
        type: "address",
        name: "owner",
        internalType: "address",
      },
    ],
    outputs: [
      {
        type: "uint256",
        name: "balance",
        internalType: "uint256",
      },
    ],
  },
];

export const NFTContract = new web3.eth.Contract(NFTABI, NFTAddress);

export const isWalletConnected = () => {
  if (localStorage.getItem("isWalletConnected") === "true") {
    return true;
  }

  return false;
};

export const connectWalletLocaly = (address) => {
  localStorage.setItem("isWalletConnected", true);
  localStorage.setItem("address", address);
};
