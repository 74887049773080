import "./App.css";
import Header from "./components/Header";
import Footer from './components/Footer';
import { Routes, Route, useNavigate } from "react-router-dom";
import HeroBG from "./assets/images/hero-bg.jpg";
import Mint from "./Pages/Mint";

function App() {
  return (
    <div
      className="App h-auto md:h-screen flex flex-col justify-between"
      style={{
        minHeight: "100vh"
      }}
      // style={{
      //   backgroundImage: `url(${HeroBG})`,
      //   backgroundSize: "cover",
      //   marginTop: "-90px",
      //   paddingTop: "90px",
      // }}
    >
      <Header />
      {/* <Routes>
        <Route path="/" element={<Mint />} />
      </Routes> */}
      <Mint />
      <Footer />
    </div>
  );
}

export default App;
